import s from './PromoBadge.module.less';
import cn from 'classnames';
import { lowerCase, upperFirst } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useEffect, useState } from 'react';
import { useOrderformFlags } from '@/hooks/helpers';
import { trackEvent } from '@/helpers/gtag';
import { updateOrderPayload } from '@/store/order/orderSlice';
import { useAppDispatch } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { ExtraFeatureTypes } from '@/types/extraFeature';
import { useGetOrderFormQuery } from '@/store/emptyApi';
import { useManageExtraFeatureOffers } from '@feature/extraFeatures/useManageExtraFeatureOffers';
import { useAuth } from '@/store/auth/authHooks';

const ClockSVG = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9538 5.375C21.9538 5.375 23.6413 9.75 20.8288 10.8438C18.4551 7.84469 20.0497 1.54688 12.3913 1.54688C12.3913 1.54688 16.3412 6.12641 13.239 11.7368C11.4739 10.0415 11.5256 8.34617 11.5256 8.34617C9.34083 10.0956 7.61638 12.326 6.49523 14.8527C5.37409 17.3794 4.88853 20.1295 5.07881 22.875C5.07881 26.2109 6.44186 29.4102 8.86811 31.7691C11.2944 34.1279 14.5851 35.4531 18.0163 35.4531C21.4475 35.4531 24.7382 34.1279 27.1645 31.7691C29.5908 29.4102 30.9538 26.2109 30.9538 22.875C30.9794 19.4747 30.1774 16.1167 28.6133 13.0753C27.0491 10.0338 24.7672 7.39525 21.9538 5.375Z"
      fill="#FF786E"
    />
    <path
      d="M18.8601 35.4235C22.1378 35.2153 25.2113 33.8027 27.4571 31.4724C29.703 29.142 30.9531 26.0684 30.9538 22.875C30.9721 19.925 30.3708 17.0026 29.1866 14.2865C28.0024 11.5705 26.2604 9.11809 24.0671 7.07957C24.0761 7.14191 24.0846 7.2048 24.0924 7.26878C24.1472 7.71718 24.1036 8.17176 23.9646 8.60261C23.8256 9.03347 23.5944 9.43082 23.2861 9.76853C22.9778 10.1062 22.5994 10.3766 22.1759 10.562C21.7524 10.7473 21.2933 10.8433 20.8288 10.8437C18.6626 8.10933 19.8 2.62417 14.1913 1.68738C15.0883 2.86901 15.6897 4.23762 15.9477 5.68461C16.2058 7.1316 16.1134 8.61715 15.678 10.0234C15.5103 10.5232 15.1838 10.9583 14.7455 11.2662C14.3072 11.5741 13.7797 11.7388 13.239 11.7368C12.5982 11.1382 12.1032 10.408 11.79 9.59957C10.0447 11.3481 8.69592 13.4329 7.83061 15.7195C6.96531 18.0061 6.60275 20.4437 6.76632 22.875C6.76703 26.0684 8.01712 29.142 10.263 31.4724C12.5089 33.8027 15.5824 35.2153 18.8601 35.4235Z"
      fill="#FF5050"
    />
    <path
      d="M10.1413 5.375C8.89874 5.375 7.8913 4.15055 7.8913 2.64062C7.89312 2.48501 7.9059 2.3297 7.92955 2.17578C7.3913 2.38495 6.9347 2.75396 6.62483 3.23021C6.31495 3.70647 6.16761 4.26568 6.2038 4.82812C6.2038 6.33805 7.21124 7.5625 8.4538 7.5625C9.5653 7.5625 10.4827 6.57812 10.6655 5.29297C10.4959 5.34506 10.3192 5.37269 10.1413 5.375Z"
      fill="#FF786E"
    />
    <path
      d="M7.92112 2.99829C7.52946 3.53192 7.32207 4.17261 7.32881 4.82813C7.29489 5.38348 7.43898 5.93538 7.74121 6.40778C8.04345 6.88017 8.48894 7.2498 9.01631 7.46571C9.4711 7.28742 9.86608 6.98991 10.1573 6.60631C10.4484 6.2227 10.6244 5.76805 10.6656 5.29298C10.4959 5.34507 10.3192 5.3727 10.1413 5.37501C8.99999 5.37501 8.06681 4.33595 7.92112 2.99829Z"
      fill="#FF5050"
    />
    <path
      d="M18 30.5312C22.3492 30.5312 25.875 27.1034 25.875 22.875C25.875 18.6466 22.3492 15.2188 18 15.2188C13.6508 15.2188 10.125 18.6466 10.125 22.875C10.125 27.1034 13.6508 30.5312 18 30.5312Z"
      fill="#E6F0FF"
    />
    <path
      d="M19.125 16.3125C17.6356 16.3128 16.1769 16.7238 14.918 17.4977C13.6592 18.2716 12.6519 19.3767 12.013 20.6847C11.3742 21.9928 11.13 23.4502 11.3087 24.8877C11.4875 26.3253 12.0819 27.6841 13.023 28.8064C14.5347 30.0036 16.4494 30.613 18.3965 30.5167C20.3437 30.4204 22.1849 29.6253 23.5639 28.2852C24.9428 26.9451 25.7614 25.1554 25.8612 23.2623C25.961 21.3693 25.335 19.5075 24.1043 18.0374C22.6999 16.9194 20.9401 16.3098 19.125 16.3125Z"
      fill="#F5FAFF"
    />
    <path
      d="M18.5625 22.3281H17.4375V18.5C17.4375 18.355 17.4968 18.2159 17.6023 18.1133C17.7077 18.0107 17.8508 17.9531 18 17.9531C18.1492 17.9531 18.2923 18.0107 18.3977 18.1133C18.5032 18.2159 18.5625 18.355 18.5625 18.5V22.3281Z"
      fill="#3F4D56"
    />
    <path
      d="M21.9375 23.4366H18.5625V22.3429H21.9375C22.0867 22.3429 22.2298 22.4005 22.3352 22.5031C22.4407 22.6056 22.5 22.7447 22.5 22.8898C22.5 23.0348 22.4407 23.1739 22.3352 23.2765C22.2298 23.379 22.0867 23.4366 21.9375 23.4366Z"
      fill="#3F4D56"
    />
    <path
      d="M18 16.8594C17.8508 16.8594 17.7077 16.8018 17.6023 16.6992C17.4968 16.5966 17.4375 16.4575 17.4375 16.3125V14.6719H18.5625V16.3125C18.5625 16.4575 18.5032 16.5966 18.3977 16.6992C18.2923 16.8018 18.1492 16.8594 18 16.8594Z"
      fill="#3F4D56"
    />
    <path
      d="M18.5625 31.0781H17.4375V29.4375C17.4375 29.2925 17.4968 29.1534 17.6023 29.0508C17.7077 28.9482 17.8508 28.8906 18 28.8906C18.1492 28.8906 18.2923 28.9482 18.3977 29.0508C18.5032 29.1534 18.5625 29.2925 18.5625 29.4375V31.0781Z"
      fill="#3F4D56"
    />
    <path
      d="M14.1379 17.4653L13.2941 16.0435L14.2684 15.4966L15.1121 16.9185C15.1867 17.0441 15.2069 17.1933 15.1683 17.3334C15.1297 17.4735 15.0354 17.593 14.9063 17.6655C14.7771 17.738 14.6235 17.7577 14.4794 17.7201C14.3353 17.6826 14.2125 17.5909 14.1379 17.4653Z"
      fill="#3F4D56"
    />
    <path
      d="M21.7316 30.2523L20.8879 28.8304C20.8133 28.7048 20.7931 28.5556 20.8317 28.4155C20.8703 28.2754 20.9645 28.1559 21.0937 28.0834C21.2229 28.0109 21.3765 27.9912 21.5206 28.0288C21.6647 28.0663 21.7875 28.158 21.8621 28.2836L22.7059 29.7054L21.7316 30.2523Z"
      fill="#3F4D56"
    />
    <path
      d="M11.8732 20.0673L10.4107 19.247L10.9732 18.2998L12.4357 19.1201C12.5649 19.1926 12.6592 19.3121 12.6978 19.4522C12.7364 19.5923 12.7162 19.7415 12.6416 19.8671C12.567 19.9928 12.4442 20.0844 12.3001 20.1219C12.156 20.1595 12.0024 20.1398 11.8732 20.0673Z"
      fill="#3F4D56"
    />
    <path
      d="M25.0262 27.4501L23.5637 26.6298C23.4345 26.5573 23.3402 26.4379 23.3016 26.2978C23.263 26.1577 23.2832 26.0084 23.3578 25.8828C23.4324 25.7572 23.5553 25.6655 23.6994 25.628C23.8434 25.5905 23.997 25.6101 24.1262 25.6826L25.5887 26.503L25.0262 27.4501Z"
      fill="#3F4D56"
    />
    <path
      d="M11.25 23.4219H9.5625V22.3281H11.25C11.3992 22.3281 11.5423 22.3857 11.6477 22.4883C11.7532 22.5909 11.8125 22.73 11.8125 22.875C11.8125 23.02 11.7532 23.1591 11.6477 23.2617C11.5423 23.3643 11.3992 23.4219 11.25 23.4219Z"
      fill="#3F4D56"
    />
    <path
      d="M26.4375 23.4219H24.75C24.6008 23.4219 24.4577 23.3643 24.3523 23.2617C24.2468 23.1591 24.1875 23.02 24.1875 22.875C24.1875 22.73 24.2468 22.5909 24.3523 22.4883C24.4577 22.3857 24.6008 22.3281 24.75 22.3281H26.4375V23.4219Z"
      fill="#3F4D56"
    />
    <path
      d="M10.9738 27.4501L10.4113 26.503L11.8738 25.6826C12.003 25.6101 12.1565 25.5905 12.3006 25.628C12.4447 25.6655 12.5676 25.7572 12.6422 25.8828C12.7168 26.0084 12.737 26.1577 12.6984 26.2978C12.6598 26.4379 12.5655 26.5573 12.4363 26.6298L10.9738 27.4501Z"
      fill="#3F4D56"
    />
    <path
      d="M23.3584 19.8671C23.2838 19.7415 23.2636 19.5923 23.3022 19.4522C23.3408 19.3121 23.4351 19.1926 23.5643 19.1201L25.0268 18.2998L25.5893 19.247L24.1268 20.0673C23.9976 20.1398 23.844 20.1595 23.6999 20.1219C23.5558 20.0844 23.433 19.9928 23.3584 19.8671Z"
      fill="#3F4D56"
    />
    <path
      d="M14.2684 30.2523L13.2941 29.7054L14.1379 28.2836C14.2125 28.158 14.3353 28.0663 14.4794 28.0288C14.6235 27.9912 14.7771 28.0109 14.9063 28.0834C15.0354 28.1559 15.1297 28.2754 15.1683 28.4155C15.2069 28.5556 15.1867 28.7048 15.1121 28.8304L14.2684 30.2523Z"
      fill="#3F4D56"
    />
    <path
      d="M21.0937 17.6655C20.9645 17.593 20.8703 17.4735 20.8317 17.3334C20.7931 17.1933 20.8133 17.0441 20.8879 16.9185L21.7316 15.4966L22.7059 16.0435L21.8621 17.4653C21.7875 17.5909 21.6647 17.6826 21.5206 17.7201C21.3765 17.7577 21.2229 17.738 21.0937 17.6655Z"
      fill="#3F4D56"
    />
    <path
      d="M18 13.0312C15.9975 13.0313 14.0399 13.6086 12.3749 14.6902C10.7098 15.7719 9.41206 17.3093 8.64572 19.108C7.87939 20.9067 7.67888 22.8859 8.06955 24.7954C8.46023 26.7049 9.42454 28.4589 10.8405 29.8356C12.2566 31.2123 14.0607 32.1498 16.0247 32.5296C17.9888 32.9094 20.0246 32.7145 21.8747 31.9694C23.7248 31.2244 25.3061 29.9627 26.4186 28.3439C27.5312 26.7251 28.125 24.8219 28.125 22.875C28.125 20.2643 27.0583 17.7605 25.1595 15.9144C23.2607 14.0684 20.6853 13.0313 18 13.0312ZM18 30.5313C16.4425 30.5313 14.9199 30.0822 13.6249 29.2409C12.3299 28.3997 11.3205 27.2039 10.7245 25.8049C10.1284 24.4059 9.97246 22.8665 10.2763 21.3813C10.5802 19.8962 11.3302 18.532 12.4315 17.4612C13.5329 16.3905 14.9361 15.6613 16.4637 15.3659C17.9913 15.0704 19.5747 15.2221 21.0136 15.8015C22.4526 16.381 23.6825 17.3624 24.5478 18.6214C25.4131 19.8805 25.875 21.3607 25.875 22.875C25.875 24.9056 25.0453 26.853 23.5685 28.2888C22.0916 29.7246 20.0886 30.5313 18 30.5313Z"
      fill="#FFD246"
    />
    <path
      d="M19.125 14.125C17.1903 14.1237 15.2959 14.6617 13.6661 15.6752C12.0363 16.6887 10.7396 18.1352 9.92963 19.8434C9.11965 21.5515 8.83039 23.4496 9.09611 25.3127C9.36183 27.1758 10.1714 28.9257 11.4289 30.3552C13.3641 31.9665 15.8534 32.8086 18.3994 32.7132C20.9454 32.6178 23.3606 31.592 25.1626 29.8406C26.9645 28.0893 28.0204 25.7415 28.1193 23.2662C28.2182 20.791 27.3529 18.3706 25.6961 16.4886C23.8671 14.9619 21.5357 14.1233 19.125 14.125ZM18 30.5312C16.4425 30.5312 14.9199 30.0822 13.6249 29.2409C12.3298 28.3997 11.3205 27.2039 10.7244 25.8049C10.1284 24.4059 9.97245 22.8665 10.2763 21.3813C10.5802 19.8962 11.3302 18.532 12.4315 17.4612C13.5329 16.3905 14.9361 15.6613 16.4637 15.3659C17.9913 15.0704 19.5747 15.2221 21.0136 15.8015C22.4526 16.381 23.6825 17.3624 24.5478 18.6214C25.4131 19.8805 25.875 21.3607 25.875 22.875C25.875 24.9056 25.0453 26.853 23.5685 28.2888C22.0916 29.7246 20.0886 30.5312 18 30.5312Z"
      fill="#FFBE37"
    />
    <path
      d="M18 24.2422C18.7767 24.2422 19.4062 23.6301 19.4062 22.875C19.4062 22.1199 18.7767 21.5078 18 21.5078C17.2233 21.5078 16.5938 22.1199 16.5938 22.875C16.5938 23.6301 17.2233 24.2422 18 24.2422Z"
      fill="#5A6978"
    />
  </svg>
);

dayjs.extend(utc);
dayjs.extend(timezone);

const calculateTimeLeft = () => {
  const now = dayjs().tz('America/New_York'); // EST timezone
  const nextMonth = now.startOf('month').add(1, 'month');
  const diffInMilliseconds = nextMonth.diff(now);
  const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60) - days * 24);
  const mins = Math.floor(diffInMilliseconds / (1000 * 60) - days * 24 * 60 - hours * 60);

  return {
    days,
    hours,
    mins,
  };
};

const getCurrentMount = () => {
  const now = dayjs().tz('America/New_York'); // EST timezone
  return now.month() + 1; // 1-12
};

export const PromoBadge = () => {
  const variant: 'draft' | 'extraQuality' = getCurrentMount() % 2 ? 'extraQuality' : 'draft';

  const { isLoggedIn } = useAuth();

  const { isOrderFormPage } = useOrderformFlags();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: orderFormConfig, isLoading } = useGetOrderFormQuery(undefined, { skip: !isLoggedIn });

  const content = {
    draft: {
      text: (
        <>
          Receive a <strong>Draft of your paper</strong> for half the price! $10&nbsp;+&nbsp;10%&nbsp;→
        </>
      ),
      textMobile: (
        <>
          Get a <strong>Paper draft</strong> for half the price!
          <br />
          Was <s>$10 + 10%</s> → Now only <strong>$5 + 5%</strong>
        </>
      ),
      discount: '$5 + 5%',
      btn: 'Draft my paper',
    },
    extraQuality: {
      text: <>Polish your paper with Extra Quality Check. Expert review, now&nbsp;at&nbsp;25%&nbsp;→</>,
      textMobile: (
        <>
          Polish your paper with <strong>Extra Quality Check</strong>. Expert review, <s>25%</s> → <strong>20%</strong>
        </>
      ),
      discount: '20%',
      btn: 'Refine my paper',
    },
  }[variant];

  const [timerValue, setTimerValue] = useState(calculateTimeLeft());
  const [visible, setVisible] = useState(false);

  const { offersEnabled } = useManageExtraFeatureOffers();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    const updateTimer = () => {
      timer = setTimeout(() => {
        setTimerValue(calculateTimeLeft());
        updateTimer();
      }, 1000);
    };
    updateTimer();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleSubmit = () => {
    if (isOrderFormPage) return;
    trackEvent({
      action: 'Banner submitted total',
      category: 'ExtraFeatures',
    });
    trackEvent({
      action: `Banner submitted ${lowerCase(variant)}`,
      category: 'ExtraFeatures',
    });

    if (variant === 'extraQuality') {
      dispatch(
        updateOrderPayload({
          extraQualityCheck: true,
          selectedFeatures: [ExtraFeatureTypes.ExtraQualityCheck],
        }),
      );
    } else {
      if (!orderFormConfig) return;
      dispatch(
        updateOrderPayload({
          draftFeature: true,
          selectedFeatures: [ExtraFeatureTypes.DraftFeature],
          words: orderFormConfig.draftFeatureSetting?.minWords || 0,
          units: undefined,
        }),
      );
    }

    navigate('/order');
  };

  useEffect(() => {
    if (isLoading) return;

    if (!offersEnabled) {
      setVisible(false);
      return;
    }

    if (isOrderFormPage) {
      setVisible(false);
      return;
    }

    setTimeout(() => {
      setVisible(true);
    }, 1500);
  }, [isOrderFormPage, isLoading, offersEnabled]);

  if (!isLoggedIn) return null;

  return (
    <div
      className={cn(s.wrapper, {
        [s.wrapperVisible]: visible,
      })}
    >
      <div
        className={cn(s.main, s[`variant${upperFirst(variant)}`], {
          [s.visible]: visible,
        })}
      >
        <div className={s.contentMobile} onClick={handleSubmit}>
          <ClockSVG />
          <div className={s.contentMobileInner}>{content.textMobile}</div>
        </div>
        <div className={s.container}>
          <div className={s.offerWrap}>
            <ClockSVG />
            <div className={s.timerWrap}>
              <div className={s.timerTitle}>Limited-time Offer!</div>
              <div className={s.timer}>
                <div className={s.timerItem}>
                  <div>{timerValue.days}</div>
                  <div>days</div>
                </div>
                <div className={s.timerItem}>
                  <div>{timerValue.hours}</div>
                  <div>hrs</div>
                </div>
                <div className={s.timerItem}>
                  <div>{timerValue.mins}</div>
                  <div>mins</div>
                </div>
              </div>
            </div>
          </div>
          <div className={s.middle}>
            <div className={s.offerText}>{content.text}</div>
            <div className={s.offerPrice}>{content.discount}</div>
          </div>
          <button type="button" className={s.orderFormLink} onClick={handleSubmit}>
            {content.btn}
          </button>
        </div>
        <button
          type="button"
          className={s.close}
          onClick={() => {
            setVisible(false);
            trackEvent({
              action: 'Banner closed total',
              category: 'ExtraFeatures',
            });
            trackEvent({
              action: `Banner closed ${lowerCase(variant)}`,
              category: 'ExtraFeatures',
            });
          }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.41 11.9999L17.71 7.70994C17.8983 7.52164 18.0041 7.26624 18.0041 6.99994C18.0041 6.73364 17.8983 6.47825 17.71 6.28994C17.5217 6.10164 17.2663 5.99585 17 5.99585C16.7337 5.99585 16.4783 6.10164 16.29 6.28994L12 10.5899L7.71 6.28994C7.5217 6.10164 7.2663 5.99585 7 5.99585C6.7337 5.99585 6.4783 6.10164 6.29 6.28994C6.1017 6.47825 5.99591 6.73364 5.99591 6.99994C5.99591 7.26624 6.1017 7.52164 6.29 7.70994L10.59 11.9999L6.29 16.2899C6.19627 16.3829 6.12188 16.4935 6.07111 16.6154C6.02034 16.7372 5.9942 16.8679 5.9942 16.9999C5.9942 17.132 6.02034 17.2627 6.07111 17.3845C6.12188 17.5064 6.19627 17.617 6.29 17.7099C6.38296 17.8037 6.49356 17.8781 6.61542 17.9288C6.73728 17.9796 6.86799 18.0057 7 18.0057C7.13201 18.0057 7.26272 17.9796 7.38458 17.9288C7.50644 17.8781 7.61704 17.8037 7.71 17.7099L12 13.4099L16.29 17.7099C16.383 17.8037 16.4936 17.8781 16.6154 17.9288C16.7373 17.9796 16.868 18.0057 17 18.0057C17.132 18.0057 17.2627 17.9796 17.3846 17.9288C17.5064 17.8781 17.617 17.8037 17.71 17.7099C17.8037 17.617 17.8781 17.5064 17.9289 17.3845C17.9797 17.2627 18.0058 17.132 18.0058 16.9999C18.0058 16.8679 17.9797 16.7372 17.9289 16.6154C17.8781 16.4935 17.8037 16.3829 17.71 16.2899L13.41 11.9999Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
